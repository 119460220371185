﻿import { AfterViewInit, ViewChild, TemplateRef } from "@angular/core";
import { DtSearchService } from "./dt.search.service";
import { DtSearchEvent } from "./SearchEvent";


export class DTSearchForm<SearchFilterType>
    implements AfterViewInit
{
    @ViewChild('filters')
    public filtersForm: TemplateRef<any>;

    public filter: SearchFilterType;
    public default: SearchFilterType;

    constructor(
        protected dtSearch: DtSearchService
    ) {
    }

    ngAfterViewInit() {
        if (this.filtersForm) {
            this.dtSearch.enable(this.filtersForm, this.filter, this.default);
        }
    }
}