﻿import { Subject, Observable } from "rxjs";
import { Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { tap } from "rxjs/operators";

import { AuthService } from "shared/services/auth.service";
import { BasePageComponent } from "shared/models/pages/basePageComponent";
import { UiService } from "shared/services/ui.service";
import { DtSearchService } from "shared/components/data-table/search/dt.search.service";
import { ISearchFilter } from "shared/components/data-table/search/ISearchFilter";
import { IHaveId } from "shared/models/global/IHaveId";
import { SystemMessagesService } from "shared/services/system-messages.service";
import { IApiService } from "shared/services/base/IApiService";
import { IApiPaging } from "shared/models/api/IApiPaging";
import { IApiListRequest } from "shared/models/api/IApiListRequest";
import { IApiOrdering } from "shared/models/api/IApiOrdering";
import { IHaveStatus } from "shared/models/api/IHaveStatus";
import { IDateInterval } from "shared/models/ui/IDateInterval";
import { IApiListResponse } from "shared/models/api/IApiListResponse";


export class BaseListPageComponent<ItemType, SearchFilterType extends ISearchFilter>
    extends BasePageComponent
{
    protected _items: ItemType[] = [];
    public get items() { return this._items; }

    protected _total: { [key: string]: string } = {};
    public get total() { return this._total }

    public get noData() { return this._items.length == 0; }

    protected dataLoaded: Subject<boolean> = new Subject<boolean>();

    protected _isLoadingNow: boolean = false;
    public get isLoadingNow(): boolean { return this._isLoadingNow; }

    public paging: IApiPaging;
    public filter: SearchFilterType;

    protected readonly defaultFilterListRequest: IApiListRequest;
    protected defaultItemsListRequest: IApiListRequest;

    public showInactive: boolean = false;

    protected auth: AuthService;
    protected ui: UiService;
    protected dtSearch: DtSearchService;
    protected i18n: TranslateService;
    protected sysMessages: SystemMessagesService;

    public deleteItemPopupVisible = false;
    public deletedItemIndex: number;

    public readonly dateIntervals: IDateInterval[] = [];

    constructor(
        injector: Injector,
        protected api: IApiService,
        protected apiUrlSegment?: string,
        public order: IApiOrdering = { sortBy: 'created', sortDesc: true }
    ) {
        super(injector);

        this.auth = injector.get(AuthService);
        this.ui = injector.get(UiService);
        this.dtSearch = injector.get(DtSearchService);
        this.i18n = injector.get(TranslateService);
        this.sysMessages = injector.get(SystemMessagesService);

        this.dateIntervals = [
            { id: this.i18n.instant('today'), from: '01-01-1970', to: '02-02-1980' },
            { id: this.i18n.instant('yesterday'), from: '03-03-1990', to: '04-04-2000' },
            { id: this.i18n.instant('last month'), from: '05-05-2000', to: '06-06-2010' }
        ];

        this.paging = {
            currentPage: 0,
            pageSize: 10
        };

        this.defaultItemsListRequest = {
            searchTerm: '',
            order: this.order,
            paging: this.paging,
            additionalParams: {}
        }

        this.defaultFilterListRequest = {
            searchTerm: '',
            order: { sortBy: 'created', sortDesc: true },
            paging: { pageSize: 9999, currentPage: 0 }
        }

        this.subscribeOn(
            this.dtSearch.searchEvent.subscribe(event => this.search(event.filters))
        );
    }


    public onPaging(paging: IApiPaging): void {
        this.paging = paging;
        this.load$().subscribe();
    }


    public onOrder(ordering: IApiOrdering): void {
        this.order = { ...ordering };
        this.load$().subscribe();
    }


    protected load$(): Observable<any> {
        this._isLoadingNow = true;
        this.ui.startBackendAction();

        this.clearData();

        this.defaultItemsListRequest.paging = this.paging;
        this.defaultItemsListRequest.order = this.order;

        return this.fetchData$().pipe(
            tap(() => {
                this._isLoadingNow = false;
                this.ui.stopBackendAction();
            })
        );
    }


    protected fetchData$(): Observable<IApiListResponse<ItemType>> {
        throw new Error("Method not implemented.");
    }


    protected clearData(): void {
        this._items = [];
    }


    protected search(filter: SearchFilterType) {
        this.filter = filter;
        this.load$().subscribe();
    }


    public deleteItemConfirm(index: number) {
        this.deleteItemPopupVisible = true;
        this.deletedItemIndex = index;
    }


    public deleteItemCancel() {
        this.deletedItemIndex = null;
        this.deleteItemPopupVisible = false;
    }


    public deleteItem() {
        if (!this.apiUrlSegment || this.apiUrlSegment.length == 0)
            throw new Error('To call deleteItemRequest method you must specify apiUrlSegment in constructor');

        this.ui.startBackendAction();
        const request = this.api.setItemStatus(this.apiUrlSegment, (this.items[this.deletedItemIndex] as IHaveId).id, 3);

        request.subscribe(
            () => {
                let item: IHaveStatus = this.items[this.deletedItemIndex];
                item.deleted = true;
                item.status = 3;

                this.ui.stopBackendAction();
                this.deleteItemCancel();
            },
            error => {
                console.error(error);
                this.ui.stopBackendAction();
                this.deleteItemCancel();
                this.sysMessages.errorMessage(error.error);
            }
        );
    }


    public activityToggle(index: number, enabled: boolean) {
        if (!this.apiUrlSegment || this.apiUrlSegment.length == 0)
            throw new Error('To call enableItemRequest method you must specify apiUrlSegment in constructor');

        this.ui.startBackendAction();

        const request = this.api.setItemStatus(this.apiUrlSegment, (this.items[index] as IHaveId).id, enabled ? 1 : 2);

        request.subscribe(
            () => {
                let item: IHaveStatus = this.items[index] as IHaveStatus;
                item.status = enabled ? 2 : 1;
                item.enabled = enabled;
                this.ui.stopBackendAction();
            },
            (error: ErrorEvent) => {
                console.error(error);
                let item: IHaveStatus = this.items[index] as IHaveStatus;
                item.status = enabled ? 1 : 2;
                item.enabled = !enabled;
                this.ui.stopBackendAction();
                this.sysMessages.errorMessage(error.error);
            }
        );
    }
}