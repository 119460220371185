﻿import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AuthService } from 'shared/services/auth.service';

import { BaseApiService } from 'shared/services/base/base-api.service';
import { IDashboardDataDto } from 'app/models/dashboard/IDashboardDataDto';

import { IProfileReadDto } from 'app/models/profile/IProfileReadDto';
import { IChangePasswordDto } from 'app/models/profile/IChangePasswordDto';
import { IProfileWriteDto } from 'app/models/profile/IProfileWriteDto';


@Injectable()
export class ApiService
    extends BaseApiService
{
    constructor(injector: Injector, auth: AuthService){
        super(injector);
        this.setAuthToken(auth.token);
    }
    
    public dashboard(type: number): Observable<IDashboardDataDto> {
        return this.get(`${BaseApiService.API_URL_SEGMENTS.REPORTS.DASHBOARD}?Type=${type}`);
    }

    public profile(): Observable<IProfileReadDto> {
        return this.get<IProfileReadDto>(BaseApiService.API_URL_SEGMENTS.PROFILE);
    }

    public profileUpdate(model: IProfileWriteDto): Observable<any> {
        return this.update<IProfileWriteDto>(BaseApiService.API_URL_SEGMENTS.PROFILE, model);
    }

    public balance(): Observable<number> {
        return of(1234.34)
    }

    public changePassword(model: IChangePasswordDto): Observable<any> {
        return this.create('accounts/password/change', model);
    }
}
